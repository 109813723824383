var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('Breadcrumb',{attrs:{"labels":_vm.labels}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"relative"},[_c('b-tabs',{staticClass:"caption-add table-custom",attrs:{"card":""}},[_c('b-tab',{staticClass:"overview-tab",attrs:{"title":"Basic Info","active":""}},[_c('validation-observer',{ref:"form_rel",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"profile-frm mb-20",staticStyle:{"border-radius":"5px"},on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',{staticClass:"align-center"},[_c('b-col',{attrs:{"lg-12":""}},[_c('div',{staticClass:"text-right mb-2"},[_c('b-button',{staticClass:"btn-df size-18 black",staticStyle:{"box-shadow":"0px 0px 4px rgba(0, 0, 0, 0.25)","margin-right":"10px"},attrs:{"type":"button","variant":"basic"},on:{"click":_vm.cancelAction}},[_vm._v("Cancel")]),(((_vm.userData && _vm.permission.create)))?[_c('b-button',{staticClass:"btn-df size-18 black",attrs:{"type":"button","variant":"primary"},on:{"click":_vm.saveAction}},[_vm._v(" Save ")])]:[_c('b-button',{staticClass:"btn-df size-18 black btn-disabled-gray",attrs:{"type":"button","variant":"primary","disabled":""}},[_vm._v(" Save ")])]],2)])],1),_c('div',{staticClass:"pb-2 mb-10",staticStyle:{"border-top":"1px solid #000000B2"}}),_c('div',{staticClass:"frm-basic size-16 black-child d-block"},[_c('b-row',{staticStyle:{"width":"100%","margin":"0"}},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"STID","label-cols-lg":"4"}},[_c('b-form-input',{attrs:{"placeholder":"Staff ID","readonly":"","required":""}})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"input-group-label",attrs:{"label":"Username","label-cols-lg":"4"}},[_c('b-form-input',{attrs:{"placeholder":"Username","formatter":_vm.length50},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Password","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required|min:12","name":"password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Password","type":"password","formatter":_vm.length50},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Re-type Password","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"Re-type Password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-confirmPass","placeholder":"Retype Password","type":"password"},model:{value:(_vm.form.re_pass),callback:function ($$v) {_vm.$set(_vm.form, "re_pass", $$v)},expression:"form.re_pass"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('div',{staticStyle:{"border-top":"1px solid #B1B1B1","height":"20px"}}),_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Staff Name","label-cols-lg":"2"}},[_c('validation-provider',{attrs:{"name":"Staff Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Name","formatter":_vm.length50},model:{value:(_vm.form.full_name),callback:function ($$v) {_vm.$set(_vm.form, "full_name", $$v)},expression:"form.full_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Email","label-cols-lg":"2"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Email","type":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required",attrs:{"label":"Role","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.selectRole,"placeholder":"Select Role"},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required",attrs:{"label":"Status","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","options":_vm.selectStatus,"placeholder":"Select Status"},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1)]}}])})],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }