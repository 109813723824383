<template>
	<section>
		<b-row class="mb-2">
		    <b-col>
				<Breadcrumb :labels="labels" />				
		    </b-col>		    
		</b-row>		
		<b-row>
		    <b-col md="12">
		    	<div class="relative">
			        <b-tabs card class="caption-add table-custom">                       
			            <b-tab title="Basic Info" active class="overview-tab">	
			            	<validation-observer
						        ref="form_rel"
						        #default="{invalid}" >
				                <b-form @submit.prevent class="profile-frm mb-20" style="border-radius: 5px">
				                	<b-row class="align-center">
							            <b-col lg-12>
							            	<div class="text-right mb-2">
							                    <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelAction" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>

							                    <template v-if="((userData && permission.create))">
							                    	<b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveAction">
								                        Save
								                    </b-button>
							                    </template>
							                    <template v-else>
							                    	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
								                        Save
								                    </b-button>
							                    </template>
							                </div>
							            </b-col>
							        </b-row>				                
					                <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
					                <div class="frm-basic size-16 black-child d-block">              
				                      	<b-row style="width: 100%; margin: 0;">   
				                          	<b-col lg="6" class="px-0">             
				                              	<b-form-group label="STID" label-cols-lg="4" class="star-required input-group-label">
				                                    <b-form-input
				                                      placeholder="Staff ID"
				                                      readonly
				                                      required
				                                    ></b-form-input>
				                              	</b-form-group>
				                          	</b-col>	
				                          	<b-col lg="6" class="px-0">             
				                              	<b-form-group label="Username" label-cols-lg="4" class="input-group-label">
													<b-form-input
														v-model="form.username"
														placeholder="Username"
														:formatter="length50"
													></b-form-input>
				                              	</b-form-group>
				                          	</b-col>	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Password" label-cols-lg="4" class="star-required input-group-label">
					                                <validation-provider
						                                #default="{ errors }"
						                                rules="required|min:12"
						                                name="password"
						                                vid="password"
						                            >
						                                <b-form-input
						                                	v-model="form.password"
					                                      	placeholder="Password"
					                                      	type="password"
					                                      	:formatter="length50"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col> 	 
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Re-type Password" label-cols-lg="4" class="star-required input-group-label">
					                                <validation-provider
						                                #default="{ errors }"
						                                name="Re-type Password"
						                                rules="required|confirmed:password"
						                            >
						                                <b-form-input
						                                	v-model="form.re_pass"
						                                	id="input-confirmPass"
					                                      	placeholder="Retype Password"
					                                      	type="password"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col>                      	
				                          	<b-col lg="12" class="px-0">
				                          		<div style="border-top: 1px solid #B1B1B1; height: 20px; "></div>
					                            <b-form-group label="Staff Name" label-cols-lg="2" class="star-required input-group-label">
					                            	<validation-provider
						                              	#default="{ errors }"
						                              	name="Staff Name"
						                              	rules="required"                                  
						                            >     
						                            	<b-form-input
						                            		v-model="form.full_name"
					                                      	placeholder="Name"
					                                      	:formatter="length50"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col>   
				                          	<b-col lg="12" class="px-0">
					                            <b-form-group label="Email" label-cols-lg="2" class="star-required input-group-label">
					                            	<validation-provider
						                              	#default="{ errors }"
						                              	name="Email"
						                              	rules="required|email"
						                            >     
						                            	<b-form-input
						                            		v-model="form.email"
					                                      	placeholder="Email"
					                                      	type="email"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col>   
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Role" label-cols-lg="4" class="star-required">	
					                            	<validation-provider
						                              	#default="{ errors }"
						                              	name="Role"
						                              	rules="required"                                  
						                            >     
						                                <v-select
						                                	v-model="selectedRole"
						                                    label="name"
						                                    :options="selectRole"
						                                    placeholder="Select Role"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col> 	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Status" label-cols-lg="4" class="star-required">	
					                            	<validation-provider
						                              	#default="{ errors }"
						                              	name="Status"
						                              	rules="required"                                  
						                            >     
						                                <v-select
						                                	v-model="selectedStatus"
						                                    label="title"
						                                    :options="selectStatus"
						                                    placeholder="Select Status"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col> 	
				                      	</b-row>
					                </div>
					            </b-form> 
					        </validation-observer>
			            </b-tab>                    
			        </b-tabs>     
			    </div>
			</b-col>
		</b-row>
	</section>
</template>
<script>

	import Breadcrumb from '../partial/Breadcrumb'

	import Datepicker from 'vuejs-datepicker'

    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
	import globalAdmin from '../model/globalAdmin'
	extend('min', {
    ...min,
    message: 'At least 12 characters and contain characters from at least two (2) of the following four (4) categories: Upper case (A through Z), Lower case (a through z), Digits (0-9), Special characters (!,$,#,%, etc)'
	});
	export default {
		mixins: [globalAdmin],
	  	components: {
	  		Breadcrumb,
	  		Datepicker,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
	  	},
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
				labels: { "staff-management-add": "" },      
				permission: [],
				form: {
			    	full_name: null,
			    	username: null,
			    	group_id: null,
			    	password: null,
			    	re_pass: null,
			    	email: null,
			    },

			    selectStatus: [
			    	{title: 'Active', value: 1},
			    	{title: 'Inactive', value: 0},
			    ],
			    selectedStatus: null,

			    selectRole: [],
			    selectedRole: null,
            }
		},		
		created(){
			this.permission = this.checkPermission(this.userData,"Staff Management");
			this.listRole()
		},
		methods: {
			length50(e){
	          	return String(e).substring(0,50)
	      	},
	      	length100(e){
	          	return String(e).substring(0,100)
	      	}, 
			listRole(){
				this.$store
				.dispatch('auth/role_list', {})
				.then(response => {
					this.selectRole = response.data.data.data
					// console.log('selectRole: ', response.data.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			cancelAction(){
				this.$router.push({ name: 'staff-management' })
			},
			saveAction(){
				this.$refs.form_rel.validate().then(success => {
                	if(success){
                		let datasStaff = {
                			email: this.form.email,
                			password: this.form.password,
                			confirm_password: this.form.password,
                			group_id: 1,
                			role_ids: this.selectedRole ? this.selectedRole.id : null,
                			username: this.form.username,
                			full_name: this.form.full_name,
                			status: this.selectedStatus ? this.selectedStatus.value : null,
                		}
                		console.log('datas: ', datasStaff)
                		this.$store
		                .dispatch('auth/createStaff', datasStaff)
		                .then(response => {
		                    this.$toast({
		                        component: ToastificationContent,
		                        props: {
		                            title: this.form.full_name + ' Added!',
		                            variant: 'success',
		                        },
		                    },
		                    {
		                        position: 'top-center'
		                    })

		                	this.$router.push({ name: 'staff-management' })
		                	
		                })
		                .catch((error) => {
		                  	console.log(error)
		                  	this.$toast({
		                      	component: ToastificationContent,
		                      	props: {
		                        	title: error.response.data.message,
		                        	variant: 'danger',
		                      	},
		                    },
		                    {
		                      position: 'top-center'
		                    })
		                }) 
                	}
                	else{
		            	 this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'One or more fields have an error. Please check and try again.',
	                          variant: 'danger',
	                        },
	                      },
	                      {
	                        position: 'top-center'
	                      }
	                    )
		            }
                })
            }
		},
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";
</style>